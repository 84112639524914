<template>
  <v-row class="inner-content">
    <v-col>

      <v-card class="pt-2">
        <v-card-text>
          <p>Here you can view and restore deleted keywords and bookings, and also view changes that were made.</p>
        </v-card-text>
      </v-card>

      <v-card class="pt-0 pl-5 elevation-0">

          <v-tabs v-model="entityTab" @change="entityTabChanged">
            <v-tab v-for="entity in entities" :key="entity">{{ entity }}</v-tab>
          </v-tabs>

          <v-tabs-items v-model="entityTab">

            <v-tab-item class="pt-3 pl-5">

              <v-tabs v-model="itemTab" @change="itemTabChanged">
                <v-tab v-for="item in items" :key="item">{{ item }}</v-tab>
              </v-tabs>

              <v-tabs-items v-model="itemTab">
                <v-tab-item>
                  <v-row class="inner-content">
                    <v-col>
                      <NKWDeletedNKWsTable v-if="entityTab == 0 && itemTab == 0" />
                    </v-col>
                  </v-row>
                </v-tab-item>

                <v-tab-item>
                  <v-row class="inner-content">
                    <v-col>
                      <NKWChangedNKWsTable v-if="entityTab == 0 && itemTab == 1" />
                    </v-col>
                  </v-row>
                </v-tab-item>

              </v-tabs-items>

            </v-tab-item>

            <v-tab-item class="pt-3 pl-5">

              <v-tabs v-model="itemTab" @change="itemTabChanged">
                <v-tab v-for="item in items" :key="item">{{ item }}</v-tab>
              </v-tabs>

              <v-tabs-items v-model="itemTab">
                <v-tab-item>
                  <v-row class="inner-content">
                    <v-col>
                      <NKWDeletedBookingsTable v-if="entityTab == 1 && itemTab == 0" />
                    </v-col>
                  </v-row>
                </v-tab-item>

                <v-tab-item>
                  <v-row class="inner-content">
                    <v-col>
                      <NKWChangedBookingsTable v-if="entityTab == 1 && itemTab == 1" />
                    </v-col>
                  </v-row>
                </v-tab-item>

              </v-tabs-items>

            </v-tab-item>

          </v-tabs-items>

      </v-card>

    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'NKWHistory',

  data () {
    return {
      entityTab: null,
      entities: ['Negative keywords', 'Bookings'],
      itemTab: null,
      items: ['Deleted', 'Changed'],
      lsEntityTab: 'nkwEntity_tab',
      lsItemTab: 'nkwItem_tab',
    }
  },

  components: {
    NKWChangedNKWsTable: () => import('@/components/tables/NKWChangedNKWsTable.vue'),
    NKWChangedBookingsTable: () => import('@/components/tables/NKWChangedBookingsTable.vue'),
    NKWDeletedNKWsTable: () => import('@/components/tables/NKWDeletedNKWsTable.vue'),
    NKWDeletedBookingsTable: () => import('@/components/tables/NKWDeletedBookingsTable.vue')
  },

  methods: {
    entityTabChanged () {
      localStorage.setItem(this.lsEntityTab, JSON.stringify(this.entityTab))
    },

    itemTabChanged () {
      localStorage.setItem(this.lsItemTab, JSON.stringify(this.itemTab))
    },
  },

  created: function () {
    if (localStorage.getItem(this.lsEntityTab)) {
      this.entityTab = JSON.parse(localStorage.getItem(this.lsEntityTab))
      if (localStorage.getItem(this.lsItemTab)) {
        this.itemTab = JSON.parse(localStorage.getItem(this.lsItemTab))
      }
    }
  },
}
</script>
